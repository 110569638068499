import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../../Agency/Particle';
import BannerWrapper from './bannerSection.style';
import RichText from '../Richtext';
import scanner from 'common/src/assets/image/agency/scanner/agency-banner.png';
import who from 'common/src/assets/image/agency/who/agency-banner.png';
import matdev from 'common/src/assets/image/agency/matdev/agency-banner.png';
import videoup from 'common/src/assets/image/agency/videoup/agency-banner.png';
import qr from 'common/src/assets/image/agency/qr/agency-banner.png';
import royalmobileapps from 'common/src/assets/image/agency/matdev/agency-banner.png';
import ereasy from 'common/src/assets/image/agency/ereasy/agency-banner.png';
import photoup from 'common/src/assets/image/agency/photoup/agency-banner.png';
import imote from 'common/src/assets/image/agency/imote/agency-banner.png';
import ai from 'common/src/assets/image/agency/ai/agency-banner.png';
import spamSafe from 'common/src/assets/image/agency/spamsafe/agency-banner.png';
import typi from 'common/src/assets/image/agency/typi/agency-banner.png';
import goya from 'common/src/assets/image/agency/goya/agency-banner.png';

const BannerSection = ({
  row,
  col,
  title,
  description,
  project,
  legalMenu,
}) => {
  const pic = {
    scanner: scanner,
    who: who,
    matdev: matdev,
    videoup: videoup,
    qr: qr,
    royalmobileapps: royalmobileapps,
    ereasy: ereasy,
    photoup: photoup,
    imote: imote,
    ai: ai,
    spamsafe: spamSafe,
    typi: typi,
    goya: goya,
  };
  const projectName = {
    main: pic[project],
  };
  return (
    <BannerWrapper project={projectName}>
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={<Heading {...title} />}
              description={<RichText {...description} />}
            />
            {legalMenu && (
              <ul>
                {legalMenu.map((menu, index) => {
                  return (
                    <li key={`menu-legal-item-${index}`}>
                      <a href={menu.slug}>{menu.title}</a>
                    </li>
                  );
                })}
              </ul>
            )}
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
  legalMenu: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  li: {
    mt: '10px',
    pr: '10px',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '70%', '60%', '50%'],
  },
  title: {
    fontSize: ['26px', '34px', '42px', '55px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#10AC84',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
